<template>
  <div class="detail" v-loading="loading">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="detail-box">
      <div class="detail-title">{{articleData.title}}</div>
      <div v-html="articleData.content" style="padding: 0 25px;" class="vhtml"></div>
      <div class="detail-source">{{articleData.publishTime}}</div>
    </div>
  </div>
</template>

<script>
import { companyeDetails } from '@/api/web'

export default {
  components: {},
  name: 'UnitDetail',
  data () {
    return {
      loading: false,
      breadcrumbList: [
        { name: '首页', path: '/' }
      ],
      articleData: ''
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    initialData () {
      this.loading = true
      companyeDetails({ id: this.$route.query.articleId }).then(res => {
        this.loading = false
        if (res.code === '000') {
          this.articleData = res.result
          const arr = res.result.items
          if (arr[0].url) {
            this.breadcrumbList.push({ name: arr[0].name, path: `${arr[0].url}?id=${arr[0].id}` })
          } else {
            this.breadcrumbList.push({ name: arr[0].name, path: `/list?id=${arr[0].id}` })
          }
          if (arr[1]) {
            if (arr[1].url) {
              this.breadcrumbList.push({ name: arr[1].name, path: `${arr[1].url}?id=${arr[0].id}&cid=${arr[1].id}` })
            } else {
              this.breadcrumbList.push({ name: arr[1].name, path: `/list/normal?id=${arr[0].id}&cid=${arr[1].id}` })
            }
          }
          // this.breadcrumbList.push({ name: arr[0].name, path: `${arr[0].url}?id=${arr[0].id}` })
          // if (arr[1]) {
          //   this.breadcrumbList.push({ name: arr[1].name, path: `${arr[0].url}?id=${arr[0].id}&cid=${arr[1].id}` })
          // }
          this.breadcrumbList.push({ name: '正文', path: '' })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail{
  padding-bottom: 30px;
}
.detail-box{
  padding: 40px 80px;
  min-height: 520px;
  background-color: #ffffff;
}
.detail-title{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E9E9E9;
  margin-bottom: 50px;
}
.detail-source{
  color: #999999;
  padding-top: 50px;
  text-align: right;
}
</style>
